import React, { useState, useEffect } from "react";
import { useCart } from "./CartContext";
import Header from "./Header";
import Footer from "./Footer";
import { motion } from "framer-motion";
import MyCartButton from "./MyCartButton";
import { useNavigate } from "react-router-dom";
import EmptyCart from "../Assets/Image/emptyCart.png";

const Cart = () => {
  const { cart, removeFromCart } = useCart();
  const [itemsCount, setItemsCount] = useState({});
  const [fullName, setFullName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [address, setAddress] = useState("");
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleDecreaseQuantity = (productId) => {
    setItemsCount((prevItemsCount) => {
      const updatedItemsCount = { ...prevItemsCount };
      if (updatedItemsCount[productId] > 1) {
        updatedItemsCount[productId] -= 1;
      } else {
        removeFromCart(productId);
      }
      return updatedItemsCount;
    });
  };

  const handleIncreaseQuantity = (productId) => {
    setItemsCount((prevItemsCount) => {
      const updatedItemsCount = { ...prevItemsCount };
      updatedItemsCount[productId] = (updatedItemsCount[productId] || 0) + 1;
      return updatedItemsCount;
    });
  };

  useEffect(() => {
    const initialItemsCount = cart.reduce((acc, product) => {
      acc[product.id] = product.itemsCount || 1;
      return acc;
    }, {});
    setItemsCount(initialItemsCount);
  }, [cart]);

  const generateWhatsAppMessage = () => {
    let message = "Order Details :\n\n";
    message += `Full Name: ${fullName}\n`;
    message += `Mobile Number: ${mobileNumber}\n`;
    message += `Address: ${address}\n\n`;

    message += "My Order :\n\n";

    cart.forEach((product, index) => {
      const itemsCountForProduct = itemsCount[product.id] || 1;
      const { updatedPrice } = calculatePriceBasedOnItemsCount(
        product,
        itemsCountForProduct
      );
      const selectedQuantity = product.selectedQuantity || 1;

      message += `${index + 1}. ${
        product.name
      } - Items: ${itemsCountForProduct}, Quantity: ${selectedQuantity}, Price: ₹${updatedPrice}\n\n`;
    });

    message += `Total Price: ₹${cart
      .reduce((total, product) => {
        const itemsCountForProduct = itemsCount[product.id] || 1;
        const { updatedPrice } = calculatePriceBasedOnItemsCount(
          product,
          itemsCountForProduct
        );
        return total + parseFloat(updatedPrice);
      }, 0)
      .toFixed(2)};`;

    return message;
  };

  const handleWhatsAppOrder = () => {
    if (!fullName || !mobileNumber || !address) {
      alert("Please fill in all the details.");
      return;
    }

    const message = generateWhatsAppMessage();
    const phoneNumber = "+917434034244";
    const encodedMessage = encodeURIComponent(message);
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
    window.open(whatsappLink, "_blank");

    setShowModal(false);
  };

  const getPriceDetails = () => {
    return cart.map((product) => {
      const itemsCountForProduct = itemsCount[product.id] || 1;
      const { updatedPrice, updatedFinalPrice } =
        calculatePriceBasedOnItemsCount(product, itemsCountForProduct);

      return {
        name: product.name,
        itemsCount: itemsCountForProduct,
        price: updatedPrice,
        finalPrice: updatedFinalPrice,
        discount: product.price - product.finalPrice,
        offer: product.offer,
      };
    });
  };

  const calculateTotal = () => {
    return cart
      .reduce((total, product) => {
        const itemsCountForProduct = itemsCount[product.id] || 1;
        const { updatedPrice } = calculatePriceBasedOnItemsCount(
          product,
          itemsCountForProduct
        );
        return total + parseFloat(updatedPrice);
      }, 0)
      .toFixed(2);
  };

  const getPriceMultiplier = (quantity) => {
    let multiplier = 1;
    switch (quantity) {
      case "1gm":
        multiplier = 0.001;
        break;
      case "5gm":
        multiplier = 0.005;
        break;
      case "10gm":
        multiplier = 0.01;
        break;
      case "20gm":
        multiplier = 0.02;
        break;
      case "50gm":
        multiplier = 0.05;
        break;
      case "100gm":
        multiplier = 0.1;
        break;
      case "150gm":
        multiplier = 0.1;
        break;
      case "200gm":
        multiplier = 0.2;
        break;
      case "250gm":
        multiplier = 0.25;
        break;
      case "500gm":
        multiplier = 0.5;
        break;
      case "1kg":
        multiplier = 1;
        break;
      case "5kg":
        multiplier = 5;
        break;
      case "15kg":
        multiplier = 15;
        break;
      case "20kg":
        multiplier = 20;
        break;
      case "100ml":
        multiplier = 0.1;
        break;
      case "200ml":
        multiplier = 0.2;
        break;
      case "250ml":
        multiplier = 0.25;
        break;
      case "500ml":
        multiplier = 0.5;
        break;
      case "1Ltr":
        multiplier = 1;
        break;
      case "2Ltr":
        multiplier = 2;
        break;
      case "5Ltr":
        multiplier = 5;
        break;
      case "15Ltr":
        multiplier = 15;
        break;
      case "1Pcs":
        multiplier = 1;
        break;
      default:
        multiplier = 1;
        break;
    }
    return multiplier;
  };

  const calculatePriceBasedOnItemsCount = (product, itemsCount) => {
    const quantity = product.selectedQuantity || "1Pcs";
    const multiplier = getPriceMultiplier(quantity);

    const updatedPrice = (product.price * itemsCount * multiplier).toFixed(2);
    const updatedFinalPrice = (
      product.finalPrice *
      itemsCount *
      multiplier
    ).toFixed(2);

    return { updatedPrice, updatedFinalPrice };
  };

  return (
    <>
      <Header />
      <div className="container my-5">
        <div className="row">
          {cart.length === 0 ? (
            <div className="col-12 text-center">
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <div className="empty-cart-message">
                  <img
                    src={EmptyCart}
                    alt="Empty Cart"
                    style={{
                      width: "150px",
                      height: "150px",
                      marginBottom: "20px",
                    }}
                  />
                  <h3>Your Cart is Empty</h3>
                  <p>
                    Looks like you haven't added any items to your cart yet.
                  </p>
                  <button
                    className="continue-shopping-btn"
                    onClick={() => navigate("/product")}
                  >
                    Continue Shopping
                  </button>
                </div>
              </motion.div>
            </div>
          ) : (
            <>
              <div className="col-lg-8 col-md-8 col-sm-12 mb-4">
                {cart.map((product) => {
                  const itemsCountForProduct = itemsCount[product.id] || 1;
                  const { updatedPrice, updatedFinalPrice } =
                    calculatePriceBasedOnItemsCount(
                      product,
                      itemsCountForProduct
                    );

                  return (
                    <>
                      <motion.div
                        key={product.id}
                        className="col-12 col-md-12 col-lg-12 mb-3"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        transition={{ duration: 0.5 }}
                      >
                        <div className="card h-100 shadow-sm rounded-3">
                          <div className="card-body p-3 d-flex flex-column flex-sm-row ">
                            <div className="me-3 mb-3 mb-sm-0">
                              {product.image && (
                                <img
                                  src={product.image[0]}
                                  alt={product.name}
                                  className="img-fluid rounded"
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    objectFit: "cover",
                                  }}
                                />
                              )}
                            </div>
                            <div className="flex-grow-1 d-flex flex-column justify-content-between">
                              <div>
                                <div className="text-small mb-2">
                                  <span className="text-muted">
                                    <small>{product.category}</small>
                                  </span>
                                </div>
                                <h2 className="fs-6 mb-1">
                                  <span className="text-dark">
                                    {product.name}
                                  </span>
                                </h2>
                                <div className="d-flex justify-content-between align-items-center mt-2">
                                  <div className="d-flex flex-wrap mb-0 align-items-center">
                                    <div className="fs-5 fw-bold me-2">
                                      <span className="text-dark">
                                        ₹{updatedPrice || "N/A"}
                                      </span>
                                    </div>
                                    {updatedFinalPrice !== updatedPrice &&
                                      updatedFinalPrice !== "0.00" && (
                                        <span className="text-danger text-decoration-line-through me-2 fs-6">
                                          ₹{updatedFinalPrice || "N/A"}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <span className="d-block mt-2">
                                  Quantity:{" "}
                                  <span className="fw-bold">
                                    {product.selectedQuantity}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end align-items-center">
                              <div className="d-flex align-items-center">
                                <button
                                  className="btn btn-outline-secondary btn-sm iconpluseMinus rounded-circle"
                                  onClick={() =>
                                    handleDecreaseQuantity(product.id)
                                  }
                                >
                                  –
                                </button>
                                <span className="mx-3">
                                  {itemsCountForProduct}
                                </span>
                                <button
                                  className="btn btn-outline-secondary btn-sm iconpluseMinus rounded-circle"
                                  onClick={() =>
                                    handleIncreaseQuantity(product.id)
                                  }
                                >
                                  +
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </motion.div>
                    </>
                  );
                })}
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 mb-4">
                <div className="SideBar_bill">
                  <div className="card shadow-sm rounded-3">
                    <div className="card-body">
                      <h5 className="text-center mb-3">Price Details</h5>
                      {getPriceDetails().map((item, index) => (
                        <div key={index} className="mb-2">
                          <div className="d-flex justify-content-between">
                            <span>
                              {item.name} ({item.itemsCount})
                            </span>
                            <span>₹{item.price}</span>
                          </div>
                        </div>
                      ))}
                      <hr />
                      <div className="d-flex justify-content-between">
                        <span>Total Amount</span>
                        <span>₹{calculateTotal()}</span>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button
                      className="btn btn-success"
                      onClick={() => setShowModal(true)}
                    >
                      Place Order via WhatsApp
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {showModal && (
        <div
          className="modal fade show"
          style={{ display: "block", backgroundColor: "rgba(0,0,0,0.5)" }}
          onClick={() => setShowModal(false)}
        >
          <div className="modal-dialog" onClick={(e) => e.stopPropagation()}>
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Enter Your Details</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={() => setShowModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">Full Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Mobile Number</label>
                  <input
                    type="text"
                    className="form-control"
                    value={mobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Address</label>
                  <textarea
                    className="form-control"
                    rows="3"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleWhatsAppOrder}
                >
                  Submit Order
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <MyCartButton />
      <Footer />
    </>
  );
};

export default Cart;
