import React from "react";
import "./App.css";
import "./Admin/Admin.css";
import RoutesComponents from "./RoutesComponents";

function App() {
  return (
    <div className="App">
      <RoutesComponents />
    </div>
  );
}

export default App;
