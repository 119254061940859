import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { useState } from "react";
import Homepage from "./Component/Homepage";
import Productdetail from "./Component/Productdetail";
import LoginAdmin from "./Admin/LoginAdmin";
import AddProducts from "./Admin/AddProducts";
import CategoriesItemsAdmin from "./Admin/CategoriesItemsAdmin";
import Dashboard from "./Admin/Dashboard";
import Cart from "./Component/Cart";
import { CartProvider } from "./Component/CartContext";

function RouterPage() {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem("username") ? true : false
  );

  const PrivateRoute = ({ element }) => {
    return isAuthenticated ? element : <Navigate to="/LoginAdmin" replace />;
  };

  return (
    <BrowserRouter>
      <CartProvider>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/:id" element={<Productdetail />} />
          <Route path="/category/:categoryName" element={<Productdetail />} />
          <Route path="/Cart" element={<Cart />} />
          <Route
            path="/LoginAdmin"
            element={<LoginAdmin setAuth={setIsAuthenticated} />}
          />
          <Route
            path="/Dashboard/*"
            element={<PrivateRoute element={<Dashboard />} />}
          >
            <Route index element={<Navigate to="AddProducts" />} />
            <Route path="AddProducts" element={<AddProducts />} />
            <Route
              path="CategoriesItemsAdmin"
              element={<CategoriesItemsAdmin />}
            />
          </Route>
          <Route path="*" element={<Homepage />} />
        </Routes>
      </CartProvider>
    </BrowserRouter>
  );
}

export default RouterPage;
