import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCecR-mIy-EkbedeazHd4T0cAqP9tX-56k",
  authDomain: "swminarayan-mega-mart.firebaseapp.com",
  projectId: "swminarayan-mega-mart",
  storageBucket: "swminarayan-mega-mart.firebasestorage.app",
  messagingSenderId: "184706659210",
  appId: "1:184706659210:web:9fb51be17e96b06f390e73",
  measurementId: "G-VE8BK7Q7FG",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, analytics };
