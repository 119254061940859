import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MyCartButton from "./MyCartButton";

function HOC(Component) {
  function NewComponent() {
    return (
      <>
        <Header />
        <Component />
        <Footer />
        <MyCartButton />
      </>
    );
  }
  return NewComponent;
}

export default HOC;
