import React, { createContext, useContext, useState, useEffect } from "react";

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cart, setCart] = useState([]);

  useEffect(() => {
    const storedCart = localStorage.getItem("cart");
    if (storedCart) {
      setCart(JSON.parse(storedCart));
    }
  }, []);

  useEffect(() => {
    if (cart.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart]);

  const addToCart = (product, selectedQuantity) => {
    setCart((prevCart) => {
      const existingProduct = prevCart.find((item) => item.id === product.id);
      if (existingProduct) {
        return prevCart.map((item) =>
          item.id === product.id
            ? {
                ...item,
                itemsCount: item.itemsCount + 1,
                selectedQuantity: selectedQuantity,
                totalPrice: (item.itemsCount + 1) * item.price,
                totalFinalPrice: (item.itemsCount + 1) * item.finalPrice,
              }
            : item
        );
      } else {
        return [
          ...prevCart,
          {
            ...product,
            itemsCount: 1,
            selectedQuantity: selectedQuantity,
            totalPrice: product.price,
            totalFinalPrice: product.finalPrice,
          },
        ];
      }
    });
  };

  const removeFromCart = (productId) => {
    setCart((prevCart) => {
      const newCart = prevCart.filter((item) => item.id !== productId);
      localStorage.setItem("cart", JSON.stringify(newCart));
      return newCart;
    });
  };

  const updateItemsCount = (productId, action) => {
    setCart((prevCart) => {
      const updatedCart = prevCart.map((product) => {
        if (product.id === productId) {
          let newItemsCount = product.itemsCount;

          if (action === "increase") {
            newItemsCount += 1;
          }

          if (action === "decrease" && product.itemsCount > 1) {
            newItemsCount -= 1;
          }

          const updatedTotalPrice = newItemsCount * product.price;
          const updatedTotalFinalPrice = newItemsCount * product.finalPrice;

          return {
            ...product,
            itemsCount: newItemsCount,
            totalPrice: updatedTotalPrice,
            totalFinalPrice: updatedTotalFinalPrice,
          };
        }
        return product;
      });

      localStorage.setItem("cart", JSON.stringify(updatedCart));
      return updatedCart;
    });
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        addToCart,
        removeFromCart,
        updateItemsCount,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
