import React, { useEffect, useState, useCallback } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "bootstrap/dist/css/bootstrap.min.css";
import MUIDataTable from "mui-datatables";
import { Modal, Button } from "react-bootstrap";
import {
  collection,
  onSnapshot,
  addDoc,
  doc,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "./firebaseConfig";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import imageCompression from "browser-image-compression";
import { useDropzone } from "react-dropzone";

const AddProducts = () => {
  const [file, setFile] = useState(null);
  const [fileError, setFileError] = useState("");
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editProductId, setEditProductId] = useState(null);

  useEffect(() => {
    const unsubscribeProducts = onSnapshot(
      collection(db, "products"),
      (snapshot) => {
        const productsData = snapshot.docs.map((doc) => {
          const productData = doc.data();
          return {
            id: doc.id,
            ...productData,
            productSize: productData.productSize || [],
          };
        });

        setData(productsData);
      }
    );

    const unsubscribeCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        const categoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      }
    );

    return () => {
      unsubscribeProducts();
      unsubscribeCategories();
    };
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      category: "",
      price: "",
      finalPrice: "",
      productSize: [],
      customSize: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Product name is required"),
      category: Yup.string().required("Category is required"),
      price: Yup.number()
        .required("Price is required")
        .positive("Price must be positive"),
      finalPrice: Yup.number().positive("Final price must be positive"),
    }),

    onSubmit: async (values) => {
      try {
        let updatedProductSize = values.productSize.includes("Custom")
          ? [
              ...new Set(
                [...values.productSize, values.customSize].filter(Boolean)
              ),
            ]
          : values.productSize;

        const newProduct = {
          ...values,
          productSize: updatedProductSize,
          image: file ? [file] : [],
          customSize: values.customSize || "",
        };

        if (isEditing) {
          await updateDoc(doc(db, "products", editProductId), newProduct);
          toast.success("Product updated successfully!");
        } else {
          await addDoc(collection(db, "products"), newProduct);
          toast.success("Product added successfully!");
        }

        formik.resetForm();
        setFile(null);
        setFileError("");
        setShowForm(false);
        setIsEditing(false);
        setEditProductId(null);
      } catch (error) {
        console.error("Error saving product:", error);
        toast.error("Error saving product!");
      }
    },
  });

  const setImage = async (selectedFile) => {
    if (selectedFile) {
      try {
        const options = {
          maxSizeMB: 2,
          maxWidthOrHeight: 800,
        };
        const compressedFile = await imageCompression(selectedFile, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile(reader.result);
          setFileError("");
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing image:", error);
        setFileError("Failed to compress image. Please try again.");
      }
    }
  };

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    setImage(file);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 1,
  });

  const removeImage = () => {
    setFile(null);
    setFileError("");
  };

  const handleView = (id) => {
    const productToView = data.find((item) => item.id === id);

    setSelectedProduct({
      ...productToView,
      customSize: productToView.customSize || "N/A",
    });
    setShowDetailModal(true);
  };

  const handleEdit = (id) => {
    const productToEdit = data.find((item) => item.id === id);
    setIsEditing(true);
    setEditProductId(id);
    setFile(productToEdit.image ? productToEdit.image[0] : null);

    // Update form values including customSize if productSize includes 'Custom'
    const initialProductSize = productToEdit.productSize || [];
    const initialCustomSize = initialProductSize.includes("Custom")
      ? productToEdit.customSize || ""
      : "";

    formik.setValues({
      name: productToEdit.name,
      category: productToEdit.category,
      price: productToEdit.price,
      finalPrice: productToEdit.finalPrice,
      productSize: initialProductSize,
      customSize: initialCustomSize, // Update customSize field
      image: productToEdit.image,
    });

    setShowForm(true);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await deleteDoc(doc(db, "products", id));
              toast.success("Product deleted successfully!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 1000,
              });
            } catch (error) {
              console.error("Error deleting product:", error);
              toast.error("Error deleting product!", {
                position: "top-right",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                autoClose: 1000,
              });
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleSizeChange = (e) => {
    const { value, checked } = e.target;

    if (checked) {
      if (value === "Custom") {
        formik.setFieldValue("productSize", [
          ...formik.values.productSize,
          value,
        ]);
      } else {
        formik.setFieldValue("productSize", [
          ...formik.values.productSize,
          value,
        ]);
      }
    } else {
      formik.setFieldValue(
        "productSize",
        formik.values.productSize.filter((size) => size !== value)
      );
      if (value === "Custom") {
        formik.setFieldValue("customSize", "");
      }
    }
  };

  const columns = [
    {
      name: "name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div
            className="text-truncate overflow-hidden"
            style={{ width: "150px" }}
          >
            {value}
          </div>
        ),
      },
    },
    {
      name: "category",
      label: "Category",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Image",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => (
          <img
            src={value[0]}
            alt="Product"
            className="img-fluid custom-img"
            style={{ width: "50px", height: "auto" }}
          />
        ),
      },
    },
    {
      name: "price",
      label: "Final Price",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div>{value ? `₹${value.toFixed(2)}` : "N/A"}</div>
        ),
      },
    },
    {
      name: "finalPrice",
      label: "Price",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div>{value ? `₹${value.toFixed(2)}` : "N/A"}</div>
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value) => (
          <div className="d-flex">
            <button
              className="btn btn-info me-1"
              onClick={() => handleView(value)}
            >
              View
            </button>
            <button
              className="btn btn-primary me-1"
              onClick={() => handleEdit(value)}
            >
              Edit
            </button>
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(value)}
            >
              Delete
            </button>
          </div>
        ),
      },
    },
  ];

  return (
    <div>
      <ToastContainer />
      <Button
        variant="success"
        onClick={() => setShowForm(true)}
        className="mb-4"
      >
        Add New Product
      </Button>
      <MUIDataTable title={"Product List"} data={data} columns={columns} />
      <Modal show={showForm} onHide={() => setShowForm(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Edit Product" : "Add New Product"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-2">
              <label htmlFor="name">
                <strong>Product Name</strong>
              </label>
              <input
                id="name"
                name="name"
                type="text"
                className="form-control"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="category">
                <strong>Category</strong>
              </label>
              <select
                id="category"
                name="category"
                className="form-control"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.category}
              >
                <option value="">Select Category</option>
                {categories.map((cat) => (
                  <option key={cat.id} value={cat.name}>
                    {cat.name}
                  </option>
                ))}
              </select>
              {formik.touched.category && formik.errors.category ? (
                <div className="text-danger">{formik.errors.category}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="price">
                <strong>Final Price</strong>
              </label>
              <input
                id="price"
                name="price"
                type="number"
                className="form-control"
                {...formik.getFieldProps("price")}
              />
              {formik.touched.price && formik.errors.price ? (
                <div className="text-danger">{formik.errors.price}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <label htmlFor="finalPrice">
                <strong>Price</strong>
              </label>
              <input
                id="finalPrice"
                name="finalPrice"
                type="number"
                className="form-control"
                {...formik.getFieldProps("finalPrice")}
              />
              {formik.touched.finalPrice && formik.errors.finalPrice ? (
                <div className="text-danger">{formik.errors.finalPrice}</div>
              ) : null}
            </div>
            <div className="form-group mb-2">
              <strong>Product Size</strong>
              <div className="d-flex flex-wrap">
                {[
                  "1gm",
                  "5gm",
                  "10gm",
                  "20gm",
                  "50gm",
                  "100gm",
                  "150gm",
                  "200gm",
                  "250gm",
                  "500gm",
                  "1kg",
                  "5kg",
                  "15kg",
                  "20kg",
                  "100ml",
                  "200ml",
                  "250ml",
                  "500ml",
                  "1Ltr",
                  "2Ltr",
                  "5Ltr",
                  "15Ltr",
                  "1Pcs",
                ].map((size) => (
                  <div
                    key={size}
                    className="form-check"
                    style={{ width: "33%" }}
                  >
                    <input
                      type="checkbox"
                      id={`productSize${size}`}
                      name="productSize"
                      value={size}
                      className="form-check-input"
                      onChange={handleSizeChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.productSize.includes(size)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`productSize${size}`}
                    >
                      {size}
                    </label>
                  </div>
                ))}
                <div className="form-check" style={{ width: "33%" }}>
                  <input
                    type="checkbox"
                    id="productSizeCustom"
                    name="productSize"
                    value="Custom"
                    className="form-check-input"
                    onChange={handleSizeChange}
                    onBlur={formik.handleBlur}
                    checked={formik.values.productSize.includes("Custom")}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="productSizeCustom"
                  >
                    Custom Size
                  </label>
                </div>
              </div>

              {formik.values.productSize.includes("Custom") && (
                <div className="form-group mt-2">
                  <label htmlFor="customSize">
                    <strong>Enter Custom Size</strong>
                  </label>
                  <input
                    id="customSize"
                    name="customSize"
                    type="text"
                    className="form-control"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customSize || ""}
                  />
                </div>
              )}
            </div>

            <div className="mb-3">
              <label className="form-label">
                <strong>Image Upload</strong>
              </label>
              <div
                className="dropzone-container"
                {...getRootProps()}
                style={{ border: "2px dashed #ccc", padding: "15px" }}
              >
                <input {...getInputProps()} />
                <p>
                  {isDragActive
                    ? "Drop the file here ..."
                    : "Drag & Drop image or click to select"}
                </p>
              </div>
              {file && (
                <div className="mt-2">
                  <img src={file} alt="Preview" className="img-fluid" />
                  <Button
                    variant="danger"
                    onClick={removeImage}
                    className="mt-2"
                  >
                    Remove Image
                  </Button>
                </div>
              )}
              {fileError && <div className="text-danger">{fileError}</div>}
            </div>
            <Button variant="primary" type="submit" className="w-100">
              {isEditing ? "Update Product" : "Add Product"}
            </Button>
          </form>
        </Modal.Body>
      </Modal>

      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Product Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProduct && (
            <div>
              <p>
                <strong>Name:</strong> {selectedProduct.name}
              </p>
              <p>
                <strong>Category:</strong> {selectedProduct.category}
              </p>
              <p>
                <strong>Final Price:</strong> ₹{selectedProduct.price}
              </p>
              <p>
                <strong>Price:</strong> ₹{selectedProduct.finalPrice}
              </p>
              <p>
                <strong>Product Size: </strong>
                {selectedProduct.productSize.join(", ")}
              </p>

              <div>
                <img
                  src={selectedProduct.image[0]}
                  alt="Product"
                  className="img-fluid"
                  style={{ width: "100%" }}
                />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddProducts;
