import React from "react";
import { TiShoppingCart } from "react-icons/ti";
import { Link } from "react-router-dom";

export default function MyCartButton() {
  return (
    <div className="my-cart-button-container">
      <Link
        to="/Cart"
        className="d-flex shadow align-items-center justify-content-center cart-button"
      >
        <TiShoppingCart className="text-white fs-3 cart-icon" />
      </Link>
    </div>
  );
}
