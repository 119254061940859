export const ProductItemlist = [
  {
    id: 1,
    image1: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
    image2: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
    name: "Groceries",
    tittle: "Fresh fruits, vegetables, staples, and more.",
    price: "$100",
    description: "A high-quality product with amazing features.",
    products: [
      {
        id: "Cold Drinks",
        name: "Cold Drinks",
        title: "appy fizz 250 ML",
        dprice: "",
        mprice: "20.00",
        image3: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "Juices",
        name: "Juices",
        title: "ala grapes juice 6PCS",
        dprice: "",
        mprice: "55.00",
        image3: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "BAKERY",
        name: "BAKERY",
        title: "amul masti buttermilk 200ML",
        dprice: "",
        mprice: "15.00",
        image3: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "BAKERY",
        name: "BAKERY",
        title: "amul masti buttermilk 200ML",
        dprice: "",
        mprice: "15.00",
        image3: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "BAKERY",
        name: "BAKERY",
        title: "amul masti buttermilk 200ML",
        dprice: "",
        mprice: "15.00",
        image3: require("../Assets/Image/IMG-20241025-WA0004.jpg"),
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
    ],
  },
  {
    id: 2,
    image1: require("../Assets/Image/IMG-20241025-WA0006.jpg"),
    image2: require("../Assets/Image/IMG-20241025-WA0006.jpg"),
    name: "Household Essentials",
    tittle: "From cleaning supplies to home decor.",
    price: "$150",
    description: "A premium product with advanced functionality.",
    products: [
      {
        id: "STATIONERY AND KIDS",
        name: "STATIONERY AND KIDS",
        title: "APSARA MATT MAGIC PENCIL |(100 PCS) 12%",
        dprice: "",
        mprice: "65.00",
        image3:
          "https://img.freepik.com/premium-photo/photo-school-stationary-items-books-table_763111-41995.jpg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "STATIONERY",
        name: "STATIONERY",
        title: "camlin spin eraser (20 PCS) jar",
        dprice: "",
        mprice: "9.00",
        image3:
          "https://img.freepik.com/premium-photo/photo-school-stationary-items-books-table_763111-41995.jpg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "ESSENCE",
        name: "ESSENCE",
        title: "cello tape 48 MM (TRANSPARENT)",
        dprice: "",
        mprice: "45.00",
        image3:
          "https://img.freepik.com/premium-photo/photo-school-stationary-items-books-table_763111-41995.jpg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "ESSENCE",
        name: "ESSENCE",
        title: "cello tape 48 MM (TRANSPARENT)",
        dprice: "",
        mprice: "45.00",
        image3:
          "https://img.freepik.com/premium-photo/photo-school-stationary-items-books-table_763111-41995.jpg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
    ],
  },
  {
    id: 3,
    image1: require("../Assets/Image/107224930-1681412037532-gettyimages-1470350052-dsc05912.jpeg"),
    image2: require("../Assets/Image/107224930-1681412037532-gettyimages-1470350052-dsc05912.jpeg"),
    name: "Electronics",
    tittle: "Latest gadgets and home appliances at competitive prices.",
    price: "$200",
    description: "Top-notch quality for professional use.",
    products: [
      {
        id: "HOME & KITCHEN",
        name: "HOME & KITCHEN",
        title: "goor met bhupes hand mate zebra",
        dprice: "",
        mprice: "100.00",
        image3:
          "https://5.imimg.com/data5/ANDROID/Default/2022/2/SJ/HA/AJ/31543065/product-jpeg-500x500.jpeg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "CLEANERS",
        name: "CLEANERS",
        title: "exo round dishwash bar 500GM",
        dprice: "",
        mprice: "55.00",
        image3:
          "https://5.imimg.com/data5/ANDROID/Default/2022/2/SJ/HA/AJ/31543065/product-jpeg-500x500.jpeg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "STEEL",
        name: "STEEL",
        title: "flower design tapeli 103 (923 G))",
        dprice: "",
        mprice: "415.00",
        image3:
          "https://5.imimg.com/data5/ANDROID/Default/2022/2/SJ/HA/AJ/31543065/product-jpeg-500x500.jpeg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "STEEL",
        name: "STEEL",
        title: "flower design tapeli 103 (923 G))",
        dprice: "",
        mprice: "415.00",
        image3:
          "https://5.imimg.com/data5/ANDROID/Default/2022/2/SJ/HA/AJ/31543065/product-jpeg-500x500.jpeg",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
    ],
  },
  {
    id: 4,
    image1: require("../Assets/Image/image_2024_10_25T12_12_48_549Z.png"),
    image2: require("../Assets/Image/image_2024_10_25T12_12_48_549Z.png"),
    name: "Fashion",
    tittle: "Trendy outfits and stylish accessories for everyone.",
    price: "$120",
    description: "Affordable and reliable, perfect for daily use.",
    products: [
      {
        id: "frims & wafers",
        name: "frims & wafers",
        title: "balaji khakhara jeera",
        dprice: "",
        mprice: "38.00",
        image3:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-stU8J2-vuL-hh_6-wAa8n4IfPEIqa60ZzQ&s",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "snacks & farsans",
        name: "snacks & farsans",
        title: "Balaji salted wafers",
        dprice: "40.00",
        mprice: "38.00",
        image3:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-stU8J2-vuL-hh_6-wAa8n4IfPEIqa60ZzQ&s",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "wafers",
        name: " wafers",
        title: "balaji wafers",
        dprice: "",
        mprice: "10.00",
        image3:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-stU8J2-vuL-hh_6-wAa8n4IfPEIqa60ZzQ&s",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: " wafers",
        name: " wafers",
        title: "balaji wafers",
        dprice: "",
        mprice: "10.00",
        image3:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-stU8J2-vuL-hh_6-wAa8n4IfPEIqa60ZzQ&s",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: " wafers",
        name: " wafers",
        title: "balaji wafers",
        dprice: "",
        mprice: "10.00",
        image3:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-stU8J2-vuL-hh_6-wAa8n4IfPEIqa60ZzQ&s",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
    ],
  },
  {
    id: 5,
    image1: require("../Assets/Image/full-length-portrait-cheery-couple_171337-2247.jpg"),
    image2: require("../Assets/Image/full-length-portrait-cheery-couple_171337-2247.jpg"),
    name: "Gift Items & Festive Essentials",
    tittle: "Unique gifts and decorations for every occasion.",
    price: "$130",
    description: "Stylish and modern design with practical features.",
    products: [
      {
        id: "personal hygiene",
        name: "personal hygiene",
        title: "boro plus crem 80ML",
        dprice: "",
        mprice: "142.00",
        image3:
          "https://www.emamiltd.in/wp-content/themes/emami/images/pro_big_image.png",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "HAIR CARE",
        name: "HAIR CARE",
        title: "clean & clear facewash 100ML",
        dprice: "",
        mprice: "160.00",
        image3:
          "https://www.emamiltd.in/wp-content/themes/emami/images/pro_big_image.png",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "SOAP & SHAMPOO",
        name: "SOAP & SHAMPOO",
        title: "GHADI DETERGENT SOAP 10/-",
        dprice: "",
        mprice: "349.00",
        image3:
          "https://www.emamiltd.in/wp-content/themes/emami/images/pro_big_image.png",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "item15",
        name: "Item15",
        title: "clinic plus S&T shampoo 650ML",
        dprice: "",
        mprice: "349.00",
        image3:
          "https://www.emamiltd.in/wp-content/themes/emami/images/pro_big_image.png",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
      {
        id: "item15",
        name: "Item15",
        title: "clinic plus S&T shampoo 650ML",
        dprice: "",
        mprice: "349.00",
        image3:
          "https://www.emamiltd.in/wp-content/themes/emami/images/pro_big_image.png",
        describ:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Beatae, et?",
      },
    ],
  },
];
