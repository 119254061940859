import React, { useState, useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { IoPersonCircle } from "react-icons/io5";
import { MdOutlineSettings, MdWifiCalling3 } from "react-icons/md";
import { TiShoppingCart } from "react-icons/ti";
import { HiOutlineBars3 } from "react-icons/hi2";
import { Link, useLocation, useNavigate } from "react-router-dom";

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeSection, setActiveSection] = useState("home");

  const location = useLocation();
  const navigate = useNavigate();

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }

    const sections = document.querySelectorAll("section");
    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (
        rect.top <= window.innerHeight / 2 &&
        rect.bottom >= window.innerHeight / 2
      ) {
        setActiveSection(section.id);
      }
    });
  };

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId)?.scrollIntoView({ behavior: "smooth" });
    setActiveSection(sectionId);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/Product") {
      window.scrollTo(0, 0);
    }
  }, [location]);

  const isProductPage = location.pathname === "/Product";

  const handleNavClick = (sectionId) => {
    if (isProductPage) {
      navigate("/", { replace: true });
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    } else {
      scrollToSection(sectionId);
    }
  };

  const ProductActive = location.pathname === "/product" ? "active" : "";

  useEffect(() => {
    if (location.pathname === "/product") {
      setActiveSection("product");
    } else if (location.pathname === "/#aboutus") {
      setActiveSection("aboutus");
    } else if (location.pathname === "/#service") {
      setActiveSection("service");
    } else if (location.pathname === "/#contactus") {
      setActiveSection("contactus");
    } else if (location.pathname !== "/cart") {
      setActiveSection("Cart");
    }
  }, [location.pathname]);

  return (
    <>
      <nav
        className={`container navbar navbar-expand-lg position-sticky bg_color shadow top-0 mt-3 rounded-pill ${
          isScrolled ? "scrolled" : ""
        }`}
        style={{ zIndex: 1000 }}
      >
        <div className="container">
          <div className="rounded-pill">
            <Link
              to={"/"}
              className="nav-link fw-bold h-100 d-flex align-content-center align-items-center"
            >
              <img
                src={require("../Assets/Image/profile picture 1.jpg")}
                alt=""
                className="img-fluid logoset"
              />
            </Link>
          </div>

          <button
            className="btn order-2 d-lg-none border-0"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasWithBothOptions"
            aria-controls="offcanvasWithBothOptions"
          >
            <span className="navbar-toggler-icon fs-5">
              <HiOutlineBars3 className="text-light fs-1" />
            </span>
          </button>

          <div className="collapse navbar-collapse lg_header" id="navbarNav">
            <ul className="navbar-nav mx-auto">
              <li className="nav-item m-1">
                <div className="sec h-100 mx-2 text-center">
                  <Link
                    to={"/"}
                    className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-center ${
                      activeSection === "home" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("home", "/")}
                  >
                    Home
                  </Link>
                </div>
              </li>

              <li className="nav-item m-1">
                <div className="sec h-100 mx-2 text-center">
                  <Link
                    to={"/product"}
                    className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-center ${ProductActive}`}
                    onClick={() => handleNavClick("product", "/product")}
                  >
                    Products
                  </Link>
                </div>
              </li>

              <li className="nav-item m-1">
                <div className="sec h-100 mx-2 text-center">
                  <Link
                    to={"/#aboutus"}
                    className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-center ${
                      activeSection === "aboutus" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("aboutus", "/#aboutus")}
                  >
                    About Us
                  </Link>
                </div>
              </li>

              <li className="nav-item m-1">
                <div className="sec h-100 mx-2 text-center">
                  <Link
                    to={"/#service"}
                    className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-center ${
                      activeSection === "service" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("service", "/#service")}
                  >
                    Service
                  </Link>
                </div>
              </li>

              <li className="nav-item m-1">
                <div className="sec h-100 mx-2 text-center">
                  <Link
                    to={"/#contactus"}
                    className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-center ${
                      activeSection === "contactus" ? "active" : ""
                    }`}
                    onClick={() => handleNavClick("contactus", "/#contactus")}
                  >
                    Contact Us
                  </Link>
                </div>
              </li>
            </ul>

            <div className="fs-1 text-white fw-bolder h-100 d-flex align-content-center align-items-center px-2">
              <Link
                to="/Cart"
                className="d-flex align-items-center justify-content-center cart-button"
              >
                <TiShoppingCart className="text-white fs-3 me-2 cart-icon" />
                <p className="text-white fs-5 m-0 cart-text">My Cart</p>
              </Link>
            </div>
          </div>
        </div>
      </nav>

      <div
        className="offcanvas offcanvas-start overflow-hidden"
        data-bs-scroll="true"
        data-bs-backdrop="false"
        tabIndex="-1"
        id="offcanvasWithBothOptions"
        aria-labelledby="offcanvasWithBothOptionsLabel"
      >
        <div className="offcanvas-header">
          <div className="fs-1 text-white fw-bolder h-100 d-flex align-content-center align-items-center px-2">
            <Link
              to="/Cart"
              className="d-flex align-items-center justify-content-center cart-button"
            >
              <TiShoppingCart className="text-white fs-3 me-2 cart-icon" />
              <p className="text-white m-0 cart-text">My Cart</p>
            </Link>
          </div>
          <button
            type="button"
            className="btn-close p-3 capbg_color rounded-circle"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div>

        <div className="offcanvas-body px-3 overflow-x-hidden ofcv_header offnav">
          <Link
            to={"/"}
            className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-start ${
              activeSection === "home" ? "active" : ""
            }`}
            onClick={() => handleNavClick("home", "/")}
          >
            <span className="fs-2 pe-2">
              <FaHome />
            </span>
            Home
          </Link>

          <Link
            to={"/#aboutus"}
            className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-start ${
              activeSection === "aboutus" ? "active" : ""
            }`}
            onClick={() => handleNavClick("aboutus", "/#aboutus")}
          >
            <span className="fs-2 pe-2">
              <IoPersonCircle />
            </span>
            About Us
          </Link>

          <Link
            to={"/product"}
            className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-start ${ProductActive}`}
            onClick={() => handleNavClick("product", "/product")}
          >
            <span className="fs-2 pe-2">
              <TiShoppingCart />
            </span>
            Product
          </Link>

          <Link
            to={"/#service"}
            className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-start ${
              activeSection === "service" ? "active" : ""
            }`}
            onClick={() => handleNavClick("service", "/#service")}
          >
            <span className="fs-2 pe-2">
              <MdOutlineSettings />
            </span>
            Service
          </Link>

          <Link
            to={"/#contactus"}
            className={`nav-link headmenu maincolor fw-medium p-2 py-3 text-start ${
              activeSection === "contactus" ? "active" : ""
            }`}
            onClick={() => handleNavClick("contactus", "/#contactus")}
          >
            <span className="fs-2 pe-2">
              <MdWifiCalling3 />
            </span>
            Contact Us
          </Link>
        </div>
      </div>
    </>
  );
}

export default Header;
