import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  onSnapshot,
} from "firebase/firestore";
import { db } from "./firebaseConfig";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import imageCompression from "browser-image-compression";

export default function CategoriesItemsAdmin() {
  const [show, setShow] = useState(false);
  const [categoryImage, setCategoryImage] = useState(null);
  const [file, setFile] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const validationSchema = Yup.object({
    name: Yup.string().required("Category name is required"),
    image: Yup.mixed().required("Category image is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      image: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!categoryImage) {
        toast.error("Please select an image for the category.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
        return;
      }

      const categoryData = {
        name: values.name,
        image: categoryImage,
      };

      try {
        if (isEdit) {
          const categoryDoc = doc(db, "categories", editId);
          await updateDoc(categoryDoc, categoryData);
          toast.success("Category updated successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        } else {
          await addDoc(collection(db, "categories"), categoryData);
          toast.success("Category added successfully!", {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
        }

        formik.resetForm();
        removeImage();
        handleClose();
      } catch (error) {
        console.error("Error adding/updating category: ", error);
        toast.error("Error adding/updating category.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    },
  });

  const handleClose = () => {
    setShow(false);
    setIsEdit(false);
    formik.resetForm();
    removeImage();
  };
  const handleShow = () => setShow(true);

  const setImage = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const options = {
        maxSizeMB: 2,
        maxWidthOrHeight: 800,
      };

      try {
        const compressedFile = await imageCompression(selectedFile, options);
        const reader = new FileReader();
        reader.onloadend = () => {
          setFile(reader.result);
          setCategoryImage(reader.result);
          formik.setFieldValue("image", compressedFile);
        };
        reader.readAsDataURL(compressedFile);
      } catch (error) {
        console.error("Error compressing the image:", error);
        toast.error("Failed to compress the image.", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    }
  };

  const removeImage = () => {
    setFile(null);
    setCategoryImage(null);
    formik.setFieldValue("image", null);
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "categories"), (snapshot) => {
      const categoriesData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCategories(categoriesData);
    });

    return () => unsubscribe();
  }, []);

  const handleEdit = (id) => {
    const categoryToEdit = categories.find((item) => item.id === id);
    formik.setValues({
      name: categoryToEdit.name,
      image: categoryToEdit.image,
    });
    setFile(categoryToEdit.image);
    setCategoryImage(categoryToEdit.image);
    setIsEdit(true);
    setEditId(id);
    setShow(true);
  };

  const handleDelete = (id) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this category?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              await deleteDoc(doc(db, "categories", id));
              setCategories(categories.filter((item) => item.id !== id));
              toast.success("Category deleted successfully!", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            } catch (error) {
              console.error("Error deleting category:", error);
              toast.error("Failed to delete category.", {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            }
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const handleBatchDelete = async (selectedRows) => {
    try {
      const idsToDelete = selectedRows.data.map(
        (row) => categories[row.index].id
      );

      const deletePromises = idsToDelete.map((id) =>
        deleteDoc(doc(db, "categories", id))
      );
      await Promise.all(deletePromises);

      setCategories(
        categories.filter((item) => !idsToDelete.includes(item.id))
      );

      toast.success("Selected categories deleted successfully!", {
        position: "top-right",
        autoClose: 1000,
        // hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    } catch (error) {
      console.error("Error deleting selected categories: ", error);
      toast.error("Failed to delete selected categories.", {
        position: "top-right",
        autoClose: 1000,
        // hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const handleView = (id) => {
    const categoryToView = categories.find((item) => item.id === id);
    setSelectedCategory(categoryToView);
    setShowDetailModal(true);
  };

  const closeDetailModal = () => {
    setShowDetailModal(false);
  };

  const columns = [
    {
      name: "name",
      label: "Category Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "image",
      label: "Category Image",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <img
            src={value}
            alt="Product"
            style={{ width: "50px", height: "auto", objectFit: "cover" }}
          />
        ),
      },
    },
    {
      name: "id",
      label: "Actions",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => (
          <div className="d-flex">
            <Button
              variant="info"
              onClick={() => handleView(value)}
              className="btn btn-primary me-1"
            >
              View
            </Button>
            <Button
              className="btn btn-primary me-1"
              onClick={() => handleEdit(value)}
            >
              Edit
            </Button>
            <Button
              className="btn btn-danger"
              onClick={() => handleDelete(value)}
            >
              Delete
            </Button>
          </div>
        ),
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    responsive: "standard",
    onRowsDelete: (rowsDeleted) => {
      handleBatchDelete(rowsDeleted);
    },
  };

  return (
    <div>
      <ToastContainer />
      <Button variant="success" onClick={handleShow} className="mb-4">
        Add New Category
      </Button>

      <Modal show={show} onHide={handleClose} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEdit ? "Edit Category" : "Add New Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group controlId="formCategoryName">
              <Form.Label className="fw-bold">Category Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category name"
                {...formik.getFieldProps("name")}
              />
              {formik.touched.name && formik.errors.name && (
                <div className="text-danger">{formik.errors.name}</div>
              )}
            </Form.Group>

            <Form.Group controlId="formCategoryImage">
              <Form.Label className="fw-bold">Category Image</Form.Label>
              <Form.Control type="file" accept="image/*" onChange={setImage} />
              {formik.touched.image && formik.errors.image && (
                <div className="text-danger">{formik.errors.image}</div>
              )}
            </Form.Group>

            {file && (
              <div>
                <img
                  src={file}
                  alt="Preview"
                  className="mt-2"
                  style={{ width: "100%", height: "auto" }}
                />
                <Button variant="danger" onClick={removeImage} className="mt-2">
                  Remove Image
                </Button>
              </div>
            )}

            <Button variant="primary" type="submit" className="mt-2">
              {isEdit ? "Update Category" : "Add Category"}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <MUIDataTable
        title={"Categories List"}
        data={categories}
        columns={columns}
        options={options}
      />

      {/* Detail Modal */}
      <Modal show={showDetailModal} onHide={closeDetailModal} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Category Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedCategory && (
            <div>
              <p>
                <strong>Product Name: </strong>
                {selectedCategory.name}
              </p>
              <img
                src={selectedCategory.image}
                alt={selectedCategory.name}
                style={{ width: "100%", height: "auto" }}
              />
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}
