import React, { useState, useEffect } from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../Admin/firebaseConfig";
import Slider from "react-slick";
import TittlesAll from "./TittleAll";
import "../Assets/Css/Aboutussec.css";
import { useCart } from "./CartContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ProductCards = () => {
  const [products, setProducts] = useState([]);
  const [quantities, setQuantities] = useState({});
  const [loading, setLoading] = useState(true);
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsData);
      setLoading(false);
    };
    fetchData();
  }, []);
  

  const handleQuantityChange = (productId, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const calculatePriceBasedOnQuantity = (product, selectedQuantity) => {
    if (!product.price || product.price <= 0) {
      return {
        updatedPrice: "",
        updatedFinalPrice: "",
      };
    }

    let priceMultiplier = 1;

    switch (selectedQuantity) {
      case "1gm":
        priceMultiplier = 0.001;
        break;
      case "5gm":
        priceMultiplier = 0.005;
        break;
      case "10gm":
        priceMultiplier = 0.01;
        break;
      case "20gm":
        priceMultiplier = 0.02;
        break;
      case "50gm":
        priceMultiplier = 0.05;
        break;
      case "100gm":
        priceMultiplier = 0.1;
        break;
      case "150gm":
        priceMultiplier = 0.1;
        break;
      case "200gm":
        priceMultiplier = 0.2;
        break;
      case "250gm":
        priceMultiplier = 0.25;
        break;
      case "500gm":
        priceMultiplier = 0.5;
        break;
      case "1kg":
        priceMultiplier = 1;
        break;
      case "5kg":
        priceMultiplier = 5;
        break;
      case "15kg":
        priceMultiplier = 15;
        break;
      case "20kg":
        priceMultiplier = 20;
        break;
      case "100ml":
        priceMultiplier = 0.1;
        break;
      case "200ml":
        priceMultiplier = 0.2;
        break;
      case "250ml":
        priceMultiplier = 0.25;
        break;
      case "500ml":
        priceMultiplier = 0.5;
        break;
      case "1Ltr":
        priceMultiplier = 1;
        break;
      case "2Ltr":
        priceMultiplier = 2;
        break;
      case "5Ltr":
        priceMultiplier = 5;
        break;
      case "15Ltr":
        priceMultiplier = 15;
        break;
      case "1Pcs":
        priceMultiplier = 1;
        break;
      default:
        priceMultiplier = 1;
        break;
    }

    const updatedPrice = product.price * priceMultiplier;
    const updatedFinalPrice = product.finalPrice * priceMultiplier;

    return {
      updatedPrice: updatedPrice > 0 ? updatedPrice.toFixed(2) : "",
      updatedFinalPrice:
        updatedFinalPrice > 0 ? updatedFinalPrice.toFixed(2) : "",
    };
  };

  const groupedProducts = products.reduce((acc, product) => {
    const category = product.category || "Others";
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(product);
    return acc;
  }, {});

  const handleAddToCart = (product) => {
    const selectedQuantity = quantities[product.id] || "1kg";
    addToCart(product, selectedQuantity);
    toast.success("Product Added to Cart!", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      autoClose: 3000,
    });
  };

  return (
    <div>
      <section className="my-lg-14 my-8">
        <Container>
          <Row>
            <Col xs={12} className="mb-5">
              <div className="col-12 overflow-hidden d-flex justify-content-center">
                <div className="tittle">
                  <TittlesAll tittle="OUR PRODUCTS" />
                </div>
              </div>
            </Col>
          </Row>

          {loading ? (
            <div className="text-center">
              <Spinner animation="border" variant="success" />
            </div>
          ) : (
            Object.keys(groupedProducts).map((category) => {
              const productsInCategory = groupedProducts[category];

              return (
                <div key={category} className="mb-3">
                  <div className="text-start">
                    <h3 className="fw-bold ms-2">{category}</h3>
                  </div>
                  <Slider {...sliderSettings} className="ProductCardSlider">
                    {productsInCategory.map((product) => {
                      const defaultQuantity = product.quantityType || "1kg";
                      const selectedQuantity =
                        quantities[product.id] || defaultQuantity;

                      const { updatedPrice, updatedFinalPrice } =
                        calculatePriceBasedOnQuantity(
                          product,
                          selectedQuantity
                        );

                      const availableQuantities = Array.isArray(
                        product.customSize
                      )
                        ? product.customSize
                        : Array.isArray(product.productSize)
                        ? product.productSize
                        : [];

                      return (
                        <div key={product.id} className="d-flex">
                          <div>
                            <div className="card card-product h-100 m-md-1 m-sm-0 m-0">
                              <div className="card-body p-0 h-100">
                                <div className="text-center position-relative d-flex justify-content-center productCardOuter">
                                  {product.image && (
                                    <span className="d-flex flex-wrap h-100">
                                      <img
                                        src={product.image[0]}
                                        alt={product.name}
                                        className="img-fluid rounded-mt-2"
                                        loading="lazy"
                                      />
                                    </span>
                                  )}
                                </div>
                                <div className="p-md-2 p-sm-2 p-1">
                                  <div className="text-small">
                                    <span className="text-muted">
                                      <small className="productcategory">
                                        {product.category ||
                                          "Category Not Available"}
                                      </small>
                                    </span>
                                  </div>
                                  <h2 className="fs-6 mb-1">
                                    <span className="text-dark productname">
                                      {product.name ||
                                        "Product Name Not Available"}
                                    </span>
                                  </h2>
                                  <div className="d-flex justify-content-between align-items-center mt-0 mb-1">
                                    <div className="d-flex flex-wrap mb-0 align-items-center">
                                      {updatedPrice && (
                                        <div className="fs-5 fw-bold me-2">
                                          <span className="text-dark productprice">
                                            ₹{updatedPrice}
                                          </span>
                                        </div>
                                      )}
                                      {updatedFinalPrice &&
                                        updatedFinalPrice !== updatedPrice && (
                                          <span className="text-danger productFinalPrice text-decoration-line-through me-2 fs-6">
                                            ₹{updatedFinalPrice}
                                          </span>
                                        )}
                                    </div>
                                  </div>

                                  <div className="d-flex align-items-center justify-content-end">
                                    <div className="quantity-select me-md-2 me-sm-2 me-1">
                                      <select
                                        id={`quantity-${product.id}`}
                                        name="quantityType"
                                        className="form-control quantity-dropdown"
                                        onChange={(e) =>
                                          handleQuantityChange(
                                            product.id,
                                            e.target.value
                                          )
                                        }
                                        value={
                                          quantities[product.id] ||
                                          selectedQuantity
                                        }
                                      >
                                        {availableQuantities
                                          .filter((size) => size !== "Custom")
                                          .map((size, index) => (
                                            <option key={index} value={size}>
                                              {size}
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <button
                                      onClick={() => handleAddToCart(product)}
                                      className="btn btn-success btn-sm w-35 pe-3 d-flex text-center addproductBtn"
                                    >
                                      <BsPlus className="fs-4" />
                                      Add
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              );
            })
          )}
        </Container>
      </section>
      <ToastContainer />
    </div>
  );
};

const sliderSettings = {
  dots: false,
  infinite: true,
  arrows: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 2,
  autoplay: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

export default ProductCards;
