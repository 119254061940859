import React, { useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";
import { collection, onSnapshot, query, where, limit, startAfter } from "firebase/firestore";
import { db } from "../Admin/firebaseConfig";
import { useCart } from "./CartContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import Footer from "./Footer";
import { Spinner } from "react-bootstrap";
import { BsPlus } from "react-icons/bs";
import MyCartButton from "./MyCartButton";

function Productdetail() {
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]); 
  const [quantities, setQuantities] = useState({});
  const [loading, setLoading] = useState(true);
  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false); 
  const { categoryName } = useParams();
  const { addToCart } = useCart();

  useEffect(() => {
    const fetchCategories = onSnapshot(
      collection(db, "categories"),
      (snapshot) => {
        const categoriesData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(categoriesData);
      },
      (error) => {
        console.error("Error fetching categories:", error);
        setLoading(false);
      }
    );

    return () => fetchCategories();
  }, []); 

  const fetchProducts = useCallback(
    (category = null, startAfterDoc = null) => {
      setLoading(true);
      const productsRef = collection(db, "products");
      let q = query(productsRef, limit(16));

      if (category) {
        q = query(q, where("category", "==", category));
      }

      if (startAfterDoc) {
        q = query(q, startAfter(startAfterDoc));
      }

      onSnapshot(
        q,
        (snapshot) => {
          const productsData = snapshot.docs.map((doc) => {
            const productData = doc.data();
            return {
              id: doc.id,
              ...productData,
              productSize: productData.productSize || [],
            };
          });

          setProducts((prevProducts) =>
            startAfterDoc ? [...prevProducts, ...productsData] : productsData
          );
          setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
          setLoading(false);
          setLoadingMore(false); 
        },
        (error) => {
          console.error("Error fetching products:", error);
          setLoading(false);
          setLoadingMore(false);
        }
      );
    },
    []
  );

  useEffect(() => {
    setProducts([]); 
    setLastVisible(null); 
    fetchProducts(categoryName);
  }, [categoryName, fetchProducts]); 

  const handleQuantityChange = (productId, quantity) => {
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: quantity,
    }));
  };

  const handleAddToCart = (product) => {
    const selectedQuantity = quantities[product.id] || "1kg";
    addToCart(product, selectedQuantity);
    toast.success("Product Added to Cart!", {
      position: "bottom-right",
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      autoClose: 3000,
    });
  };

  const handleScroll = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const bottomPosition = document.documentElement.scrollHeight;

    if (scrollPosition + 100 >= bottomPosition && !loading) {
      loadMoreProducts();
    }
  };

  const loadMoreProducts = () => {
    if (lastVisible) {
      setLoadingMore(true); 
      fetchProducts(categoryName, lastVisible);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loading, lastVisible]);

  const filteredProducts = categoryName
    ? products.filter((product) => product.category === categoryName)
    : products;

  return (
    <div>
      <Header />
      {loading && !products.length ? (
        <div className="d-flex justify-content-center align-items-center my-5">
          <Spinner animation="border" variant="success" />
        </div>
      ) : (
        <div className="container p-0">
          <div className="row m-0 p-0 py-4 justify-content-center">
            <div
              className="col-3 stickysidebar p-md-2 p-1"
              style={{
                position: "sticky",
                top: 90,
                height: "75vh",
                overflowY: "scroll",
                zIndex: 999,
                WebkitScrollbarWidth: "none",
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <div className="shadow-sm py-md-4 py-sm-2 py-1 px-md-3 px-sm-1 px-1">
                <h5 className="">All Categories</h5>
                {categories.map((category) => (
                  <Link
                    key={category.id}
                    to={`/category/${category.name}`}
                    className={`fw-bold py-md-2 py-sm-2 py-1 px-md-2 px-sm-1 px-1 d-block text-decoration-none border sidebarhover mt-md-3 mt-sm-2 mt-1 ${
                      category.name === categoryName
                        ? "bg-success text-white"
                        : "text-success"
                    }`}
                  >
                    <div className="d-flex align-items-center category_name_img">
                      {category.image && (
                        <img
                          src={category.image}
                          alt={category.name}
                          className="category_image"
                          style={{
                            width: "30px",
                            height: "30px",
                            objectFit: "cover",
                          }}
                        />
                      )}
                      {category.name}
                    </div>
                  </Link>
                ))}
              </div>
            </div>

            <div className="col-9 pt-md-4 pt-0">
              <div className="text-start mb-3 mt-2">
                <h5>{categoryName ? categoryName : "All Products"}</h5>
              </div>

              <div className="row">
                {filteredProducts.length === 0 ? (
                  <p>No products found.</p>
                ) : (
                  filteredProducts.map((product) => {
                    const availableQuantities = Array.isArray(product.customSize)
                      ? product.customSize
                      : Array.isArray(product.productSize)
                      ? product.productSize
                      : [];

                    const selectedQuantity = quantities[product.id] || "1kg";

                    const { updatedPrice, updatedFinalPrice } = calculatePriceBasedOnQuantity(
                      product,
                      selectedQuantity
                    );

                    return (
                      <div key={product.id} className="col-lg-3 col-md-4 col-sm-6 col-6 mb-2 px-1">
                        <div>
                          <div className="card card-product h-100 m-md-1 m-sm-0 m-0">
                            <div className="card-body p-0 h-100">
                              <div className="text-center position-relative d-flex justify-content-center productCardOuter">
                                {product.image && (
                                  <span className="d-flex flex-wrap h-100">
                                    <img
                                      src={product.image[0]}
                                      alt={product.name}
                                      className="img-fluid rounded-mt-2"
                                    />
                                  </span>
                                )}
                              </div>
                              <div className="p-md-2 p-sm-2 p-1">
                                <div className="text-small">
                                  <span className="text-muted">
                                    <small className="productcategory">
                                      {product.category || "Category Not Available"}
                                    </small>
                                  </span>
                                </div>
                                <h2 className="fs-6 mb-1">
                                  <span className="text-dark productname">
                                    {product.name || "Product Name Not Available"}
                                  </span>
                                </h2>
                                <div className="d-flex justify-content-between align-items-center mt-0 mb-1">
                                  <div className="d-flex flex-wrap mb-0 align-items-center">
                                    {updatedPrice && (
                                      <div className="fs-5 fw-bold me-2">
                                        <span className="text-dark productprice">
                                          ₹{updatedPrice}
                                        </span>
                                      </div>
                                    )}
                                    {updatedFinalPrice && updatedFinalPrice !== updatedPrice && (
                                      <span className="text-danger productFinalPrice text-decoration-line-through me-2 fs-6">
                                        ₹{updatedFinalPrice}
                                      </span>
                                    )}
                                  </div>
                                </div>

                                <div className="d-flex align-items-center justify-content-end">
                                  <div className="quantity-select me-md-2 me-sm-2 me-1">
                                    <select
                                      id={`quantity-${product.id}`}
                                      name="quantityType"
                                      className="form-control quantity-dropdown"
                                      onChange={(e) =>
                                        handleQuantityChange(product.id, e.target.value)
                                      }
                                      value={quantities[product.id] || selectedQuantity}
                                    >
                                      {availableQuantities
                                        .filter((size) => size !== "Custom")
                                        .map((size, index) => (
                                          <option key={index} value={size}>
                                            {size}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <button
                                    onClick={() => handleAddToCart(product)}
                                    className="btn btn-success btn-sm w-35 pe-3 d-flex text-center addproductBtn"
                                  >
                                    <BsPlus className="fs-4" />
                                    Add
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
                
                {loadingMore && (
                  <div className="col-12 d-flex justify-content-center my-3">
                    <Spinner animation="border" variant="success" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
      <MyCartButton />
      <Footer />
    </div>
  );
}

const calculatePriceBasedOnQuantity = (product, selectedQuantity) => {
  if (!product.price || product.price <= 0) {
    return {
      updatedPrice: "",
      updatedFinalPrice: "",
    };
  }

  let priceMultiplier = 1;

  switch (selectedQuantity) {
    case "1gm":
      priceMultiplier = 0.001;
      break;
    case "5gm":
      priceMultiplier = 0.005;
      break;
    case "10gm":
      priceMultiplier = 0.01;
      break;
    case "20gm":
      priceMultiplier = 0.02;
      break;
    case "50gm":
      priceMultiplier = 0.05;
      break;
    case "100gm":
      priceMultiplier = 0.1;
      break;
    case "150gm":
      priceMultiplier = 0.15;
      break;
    case "200gm":
      priceMultiplier = 0.2;
      break;
    case "250gm":
      priceMultiplier = 0.25;
      break;
    case "500gm":
      priceMultiplier = 0.5;
      break;
    case "1kg":
      priceMultiplier = 1;
      break;
    case "5kg":
      priceMultiplier = 5;
      break;
    case "15kg":
      priceMultiplier = 15;
      break;
    case "20kg":
      priceMultiplier = 20;
      break;
    case "100ml":
      priceMultiplier = 0.1;
      break;
    case "200ml":
      priceMultiplier = 0.2;
      break;
    case "250ml":
      priceMultiplier = 0.25;
      break;
    case "500ml":
      priceMultiplier = 0.5;
      break;
    case "1Ltr":
      priceMultiplier = 1;
      break;
    case "2Ltr":
      priceMultiplier = 2;
      break;
    case "5Ltr":
      priceMultiplier = 5;
      break;
    case "15Ltr":
      priceMultiplier = 15;
      break;
    case "1Pcs":
      priceMultiplier = 1;
      break;
    default:
      priceMultiplier = 1;
      break;
  }

  const updatedPrice = product.price * priceMultiplier;
  const updatedFinalPrice = product.finalPrice * priceMultiplier;

  return {
    updatedPrice: updatedPrice > 0 ? updatedPrice.toFixed(2) : "",
    updatedFinalPrice: updatedFinalPrice > 0 ? updatedFinalPrice.toFixed(2) : "",
  };
};

export default Productdetail;
